const GROUPS = {
  broadcasters: { label: 'Operadores', icon: 'videos' },
  assets: { label: 'Multimedia', icon: 'galeriafotos' },
  football: { label: 'Fútbol', icon: 'datosdepartido' },
  standings: { label: 'Standings', icon: 'datosdeclub' },
  digitalAssets: { label: 'Digital Assets', icon: 'inbox' },
  transversal: { label: 'Transversal', icon: 'archivos' },
  news: { label: 'News', icon: 'noticias' },
  layouts: { label: 'Layouts', icon: 'inbox' },
  highlights: { label: 'Highlights', icon: 'inbox' },
  pages: { label: 'Pages', icon: 'noticias' },
  middleware: { label: 'Middleware', icon: 'inbox' },
  experience: { label: 'LaLiga Experience', icon: 'inbox' },
  promises: { label: 'LaLiga Promises', icon: 'inbox' },
  internalEvents: { label: 'Internal Events', icon: 'inbox' },
  ambassadors: { label: 'Ambassadors', icon: 'inbox' },
  microsoft: { label: 'Microsoft', icon: 'datosdepartido' },
  sportium: { label: 'Sportium', icon: 'inbox' },
  beyondStats: { label: 'Beyond Stats', icon: 'inbox' },
  partidazos: { label: 'Partidazos', icon: 'inbox' },
  appOficial: { label: 'App Oficial', icon: 'inbox' },
}

export default GROUPS
