import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import React, { Component, Fragment } from 'react'
import { BooleanInput, DisabledInput, FormDataConsumer, maxLength, NumberInput, ReferenceInput, required, TextInput } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

import { AutocompleteInput, ColorPicker, ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

const FORM = 'record-form'
const selector = formValueSelector(FORM)
const styles = (theme) => ({
  rowIndex: {
    padding: '10px',
    alignSelf: 'flex-start',
  },
  rowBroadcastCountries: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rowContent: {
    flex: 'auto',
    padding: '0px 10px',
  },
})

export class BroadcastersForm extends Component {
  addRow(source) {
    const { field, change } = this.props
    let items = field(source)
    if (!items) {
      items = []
    }
    items.push({})
    change(FORM, source, items)
  }

  removeRow(source, key) {
    const { field, change } = this.props
    let items = field(source)
    items.splice(key, 1)
    change(FORM, source, items)
  }

  BroadcasterCountries = ({ source }) => {
    const { field, classes } = this.props
    const items = field(source)
    return (
      <Fragment>
        {items &&
          items.map((item, key) => {
            return (
              <div className={classes.rowBroadcastCountries} key={key}>
                <div className={classes.rowIndex}>{key + 1}</div>
                <div className={classes.rowContent}>
                  <ReferenceInput label="Country" source={`${source}[${key}].country`} reference={getResource('transversal', 'countries')} fullWidth>
                    <AutocompleteInput inputValueMatcher={() => null} fullWidth />
                  </ReferenceInput>
                  <TextInput source={`${source}[${key}].web`} label="Web" validate={[maxLength(255)]} fullWidth />
                  <TextInput source={`${source}[${key}].webResidential`} label="Web Residential LaLiga Santander" validate={[maxLength(255)]} fullWidth />
                  <TextInput source={`${source}[${key}].webLocal`} label="Web Local" validate={[maxLength(255)]} fullWidth />
                  <BooleanInput source={`${source}[${key}].secondary`} label="Secondary" />
                  <TextInput
                    source={`${source}[${key}].webResidentialSecondary`}
                    label="Web Residential LaLiga Smartbank"
                    validate={[maxLength(255)]}
                    fullWidth
                  />
                  <BooleanInput source={`${source}[${key}].residentialSecondary`} label="Residential LaLiga Smartbank" />
                </div>
                <Button size="small" onClick={() => this.removeRow(source, key)}>
                  <CloseIcon />
                  Remove
                </Button>
              </div>
            )
          })}

        <Button size="small" onClick={() => this.addRow(source)}>
          <AddIcon className={classes.leftIcon} />
          Add
        </Button>
      </Fragment>
    )
  }

  render() {
    const { BroadcasterCountries } = this

    return (
      <LanguagesNav {...this.props}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <DisabledInput fullWidth label="Slug" source="slug" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput
              source="image"
              label="Image"
              accept="image/*"
              options={{
                remove: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput
              source="imageSecondary"
              label="Image Secondary"
              accept="image/*"
              options={{
                remove: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput fullWidth label="Nombre" source="name" validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput fullWidth label="Priority" source="priority" validate={[maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth label="Web" source="web" validate={[maxLength(255)]} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColorPicker label="Color" name="color" source="color" validate={[required(), maxLength(7)]} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInput label="Ocultar en el mapa" source="hiddenMap" />
          </Grid>
          <Grid item xs={12}>
            <BroadcasterCountries source="broadcasterCountries" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInput label="Residential LaLiga Santander" source="residential" />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (!formData) return null
                if (formData.residential) {
                  return <TextInput fullWidth label="Web Residential LaLiga Santander" source="webResidential" validate={[maxLength(255)]} />
                }
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInput label="Local" source="local" />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (!formData) return null
                if (formData.local) {
                  return <TextInput fullWidth label="Web Local" source="webLocal" validate={[maxLength(255)]} />
                }
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInput label="Residential LaLiga Smartbank" source="residentialSecondary" />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (!formData) return null
                if (formData.residentialSecondary) {
                  return <TextInput fullWidth label="Web Residential LaLiga Smartbank" source="webResidentialSecondary" validate={[maxLength(255)]} />
                }
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(BroadcastersForm)
