import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import languages, { additionalLanguages } from '../../../config/languages'
import { withFormActiveLanguage } from '../../languagesNav/formActiveLanguageContext'

const LanguageInput = (props) => {
  const {
    meta,
    label,
    formLanguageData: { useAdditionalLanguages, formActiveLanguage },
    ...rest
  } = props
  const { value, onChange } = props.input ? props.input : props

  const languagesToUse = useAdditionalLanguages ? additionalLanguages : languages

  let showAtThisLanguage = false
  if (value) {
    showAtThisLanguage = !!value.find((e) => e.language === formActiveLanguage)
  }

  const handleChange = (e) => {
    let newValue = value ? [...value] : []
    if (e.target.value) {
      if (!showAtThisLanguage) {
        const lang = languagesToUse.find((e) => e.slug === formActiveLanguage)
        newValue.push({ language: lang.slug, name: lang.title })
      }
    } else {
      const index = newValue.findIndex((e) => e.language === formActiveLanguage)
      if (index > -1) {
        newValue.splice(index, 1)
      }
    }
    onChange(newValue)
  }

  return (
    <FormControl fullWidth error={meta.error && meta.touched} {...rest}>
      <InputLabel>Show in {languagesToUse.find((e) => e.slug === formActiveLanguage).title}?</InputLabel>
      <Select
        value={showAtThisLanguage}
        onChange={handleChange}
        inputProps={{
          name: 'show-language',
          id: 'show-language',
        }}
      >
        <MenuItem value={false}>No</MenuItem>
        <MenuItem value={true}>Yes</MenuItem>
      </Select>
    </FormControl>
  )
}

export default withFormActiveLanguage(LanguageInput)
