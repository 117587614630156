import { Button, withStyles } from '@material-ui/core'
import RegenerateIcon from '@material-ui/icons/Refresh'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { fetchEnd, fetchStart, showNotification } from 'react-admin'

import { dataProvider } from '../../../providers'
import { RESIZE } from '../../../providers/dataProvider/types'

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing.unit,
  },
  buttonIcon: {
    fontSize: '18px',
    marginRight: theme.spacing.unit / 2,
  },
})

class AdditionalToolbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { resource, fetchEnd, fetchStart, showNotification } = this.props
    const { id } = this.props.record
    if (id) {
      fetchStart()
      this.setState({
        isFetching: true,
      })
      dataProvider(RESIZE, resource, {
        id: id,
      })
        .then(() => {
          showNotification('Resize success', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
          this.setState({
            isFetching: false,
          })
        })
    }
  }

  render() {
    const { isFetching } = this.state
    const { classes } = this.props

    return (
      <Button className={classes.button} variant="contained" onClick={this.handleClick} disabled={isFetching}>
        <RegenerateIcon className={classes.buttonIcon} />
        RESIZE
      </Button>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(AdditionalToolbar)
