import { Grid } from '@material-ui/core'
import React from 'react'
import { BooleanInput, maxLength, required, TextInput } from 'react-admin'

import { LanguagesNav } from '../../../../components'
import { ImageInput, RichTextInput } from '../../../../components/inputs'
import GameweekSelector from './GameweekSelector'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <BooleanInput label="Active" source="active" />
      </Grid>
    </Grid>
    <GameweekSelector {...props} />
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth source="videoTitle" label="Title" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput fullWidth source="videoDescription" label="Description" validate={[required()]} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageInput
          label="Video"
          source="video"
          validate={[required()]}
          options={{
            type: 'videos',
            filters: {
              state: 'published',
            },
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
