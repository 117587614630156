import React, { Fragment } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import { FormDataConsumer, TextInput, BooleanInput, LongTextInput, required } from 'react-admin'
import { AddAssetInput, MetadataInput } from '../../../../components/inputs'
import Grid from '@material-ui/core/Grid'

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
    display: 'inline-block',
    textDecoration: 'none',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  iconSmall: {
    fontSize: 20,
    marginLeft: theme.spacing.unit,
  },
})

export const Form = ({ recordForm, form, classes, ...props }) => (
  <Grid container>
    <Grid item xs={12} sm={12} md={4}>
      <AddAssetInput source="asset" label="Picture" accept="image/*" fullWidth validate={[required()]} cropperOptions />
    </Grid>
    <Grid item xs={12} sm={12} md={8}>
      <Grid container>
        <Grid item xs={12}>
          <TextInput source="name" label="Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BooleanInput label="Watermarked" source="watermarked" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BooleanInput label="Enabled size XXL" source="enabledXXL" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BooleanInput label="Force convert jpg" source="forceConvert" defaultValue={true} />
        </Grid>
      </Grid>
    </Grid>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData && (
          <Fragment>
            {formData.watermakedUrl && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.label}>
                  Watermark
                </Typography>
                <a href={formData.watermakedUrl} target="_blank" rel="noopener noreferrer" className={classes.button}>
                  <Button variant="contained" size="small" color="primary">
                    Watermark
                    <ImageIcon className={classes.iconSmall} />
                  </Button>
                </a>
              </Grid>
            )}
            {formData.resizes && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.label}>
                  Resizes
                </Typography>
                {formData.resizes.map((item) => (
                  <a href={item.url} target="_blank" rel="noopener noreferrer" key={item.type} className={classes.button}>
                    <Button variant="contained" size="small" color="primary">
                      {item.type}
                      <ImageIcon className={classes.iconSmall} />
                    </Button>
                  </a>
                ))}
              </Grid>
            )}
          </Fragment>
        )
      }
    </FormDataConsumer>
    <Grid item xs={12}>
      <LongTextInput label="Caption" source="caption" />
    </Grid>
    <Grid item xs={12}>
      <MetadataInput source="metadatas" label="Metadata" />
    </Grid>
  </Grid>
)

export default withStyles(styles)(Form)
